.medical-records-upload {
    display: flex;
    height: 100vh;
}

.left-side {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.left-side img {
    width: 100%;
    object-fit: cover;
}

.right-side {
    flex: 1;
    padding: 50px;
}

h2 {
    font-size: 3rem;
    margin-top: 20px;
    margin-bottom: 30px;
    font-weight: bold;
}

p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.5;
    width: 90%;
}

form {
    display: flex;
    flex-direction: column;
}

label {
    font-size: 1.2rem;
    margin-bottom: 5px;
}

.upload-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.4);
    height: 40px;
}

input[type="file"] {
    padding-top: 20px;
    height: auto;
}

button[type="submit"] {
    margin-left: 90px;
    margin-top: 20px;
    width: 50%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #769fcd;
    color: #fff;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
    background-color: #0066cc;
}

h4 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    text-align: center;
}

.loading {
    height: 300px;
    margin-left: 178px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

}