@import url("https://fonts.googleapis.com/css2?family=Inter&family=Rajdhani:wght@700&family=Rubik:wght@600&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	color: rgb(64, 90, 95);
}

::placeholder {
	color: white;
}

.main-container {
	width: 100vw;
	height: 100vh;
	background-image: url("../../images/home.jpg");

	/* background: rgba(0, 0, 0, 0.4); */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.body-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: reverse;
	justify-content: flex-start;
}

.login-container {
	width: 33%;
	height: 65%;
	padding: 25px 30px;
	margin-left: 160px;

	border-radius: 15px;
	/* background: rgba(0, 0, 0, 0.5); */

	/* opacity: 40%; */
}

.login-container .heading {
	padding: 0;
	margin-bottom: 0;
}

.login-container>.heading>h2 {
	font-weight: 600;
	color: rgb(64, 90, 95);
	font-size: 52px;
}

.login-container>form {
	margin: 20px 0 5px 0;
}

.login-container>form>input {
	font-size: 17px;
	padding: 0 15px;
	width: 100%;
	margin: 0px 0px 12px;
	height: 50px;
	border-radius: 7px;
	border: 1px solid black;
	background-color: transparent;
	color: rgb(64, 90, 95);
	outline: none;
}

.login-container>form>p {
	font-size: 15px;
	margin: 10px 10px 20px 65%;
	font-weight: 400;
}

.login-container>form .login-btn>input {
	font-size: 18px;
	font-weight: 600;
	width: 100%;
	height: 40px;
	border-radius: 5px;
	border: none;
	color: white;
	background-color: #769fcd;
}

.login-container .tosignup {
	font-size: 16px;

	width: 71%;
	margin: 15px auto;
}

.login-container .tosignup>h4 {
	font-weight: 400;
	font-size: 16px;
}

.heading-container>h1 {
	font-size: 59px;
	position: relative;
	top: 15px;
	text-align: center;

	font-weight: 700;
	letter-spacing: 1.3px;
}

.login-btn:active {
	transform: scale(1.1);
	transition: 0.5s;
}

@media (max-width: 640px) {
	.login-container {
		width: 90%;
		height: 69%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		margin: 30px 0;
		padding: 10px;
	}

	.login-container>.heading>h2 {
		margin: 10px 0;
		font-size: 40px;
	}

	.login-container .tosignup {
		width: 75%;
	}

	.login-container>form .login-btn>input {
		height: 45px;
	}
}

.login-container>form .login-btn>input:hover {
	transform: scale(1.03);
	transition: 0.2s;
}

/* Small Desktops */
@media (min-width: 1280px) and (max-width: 1439px) {}

/* Medium Desktops */
@media (min-width: 1440px) and (max-width: 1919px) {}