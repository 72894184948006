@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@500&display=swap");

.navbar-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: #f7fbfc;
	z-index: 1000;
}

.logo-container {
	position: absolute;
	width: 218px;
	margin-left: 120px;
	margin-top: 25px;
}

.hide-logo {
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.35s, visibility 0.35s;
}

.show-logo {
	opacity: 1;
	visibility: visible;
	transition: opacity 0.35s, visibility 0.35s;
}

.button_container {
	position: fixed;
	top: 5%;
	left: 2%;
	height: 27px;
	width: 35px;
	cursor: pointer;
	z-index: 100;
	transition: opacity 0.25s ease;
}

.button_container:hover {
	opacity: 0.7;
}

.button_container.active .top {
	transform: translateY(11px) translateX(0) rotate(45deg);
	background: #405a5f;
}

.button_container.active .middle {
	opacity: 0;
	background: #405a5f;
}

.button_container.active .bottom {
	transform: translateY(-11px) translateX(0) rotate(-45deg);
	background: #405a5f;
}

.button_container span {
	background: #405a5f;
	opacity: 0.8;
	border: none;
	height: 5px;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	transition: all 0.35s ease;
	cursor: pointer;
	border-radius: 20%;
}

.button_container span:nth-of-type(2) {
	top: 11px;
}

.button_container span:nth-of-type(3) {
	top: 22px;
}

.button_container .closed {
	width: 70%;
}

.overlay {
	position: fixed;
	background: #f7fbfc;
	top: 0;
	left: 0;
	width: 100%;
	height: 0%;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.35s, visibility 0.35s, height 0.35s;
	overflow: hidden;
}

.overlay.open {
	opacity: 0.9;
	visibility: visible;
	height: 100%;
	z-index: 500;
}

.overlay.open li {
	animation: fadeInRight 0.5s ease forwards;
	animation-delay: 0.35s;
}

.overlay.open li:nth-of-type(2) {
	animation-delay: 0.4s;
}

.overlay.open li:nth-of-type(3) {
	animation-delay: 0.45s;
}

.overlay.open li:nth-of-type(4) {
	animation-delay: 0.5s;
}

.overlay.open li:nth-of-type(5) {
	animation-delay: 0.55s;
}

.overlay.open li:nth-of-type(6) {
	animation-delay: 0.6s;
}

.overlay.open li:nth-of-type(7) {
	animation-delay: 0.65s;
}

.overlay nav {
	position: relative;
	height: 50%;
	top: 30%;
	transform: translateY(-50%);
	font-size: 50px;
	font-family: "Encode Sans", sans-serif;
	font-weight: 400;
	text-align: center;
}

.overlay ul {
	list-style: none;
	padding: 0;
	margin: 0 auto;
	display: inline-block;
	position: relative;
	height: 100%;
}

.overlay ul li {
	display: block;
	height: 100%;
	height: calc(100% / 4);
	min-height: 50px;
	position: relative;
	opacity: 0;
}

.overlay ul li a {
	display: block;
	position: relative;
	color: #769fcd;
	text-decoration: none;
	overflow: hidden;
}

.overlay ul li a:hover:after,
.overlay ul li a:focus:after,
.overlay ul li a:active:after {
	width: 100%;
}

.overlay ul li a:after {
	content: "";
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0%;
	transform: translateX(-50%);
	height: 3px;
	background: #1f1f1f;
	transition: 0.35s;
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		left: 20%;
	}

	100% {
		opacity: 1;
		left: 0;
	}
}

.overlay ul li a.active:after {
	width: 100%;
	background-color: #1f1f1f;
}

@media (max-width: 640px) {
	.logo-container {
		margin-left: 80px;
	}
}