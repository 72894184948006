.profile-main-container {
	width: 100vw;
	height: 100vh;
	background-image: url("../../images/profilebg.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	align-items: center;
	justify-content: center;
	color: black;
}

.profile-container {
	width: 70%;
	height: 70%;
	padding: 20px 40px;
}

.profile-container>.profile-heading {
	margin: 20px 0;
}

.profile-container>.profile-heading>h2 {
	text-align: center;
	font-size: 33px;
}

.profile-container>.profile-heading>p {
	margin: 0;
	text-align: center;
	color: red;
}

.profile-container>.profile-inputs {
	display: grid;
	grid-template-rows: repeat(6, 1fr);
	grid-template-columns: repeat(2, 1fr);
	column-gap: 20px;
}

.profile-container>.profile-inputs>input,
select {
	font-size: 15px;
	padding: 0 15px;
	width: 100%;
	margin: 12px 0;
	height: 43px;
	border-radius: 5px;
	color: black;
	border: 1px solid grey;
	background-color: transparent;

	outline: none;
}

.profile-container>.profile-inputs>.full-input {
	grid-column: 1 / span 2;
}

.profile-container>.profile-submit {
	display: flex;
	justify-content: center;
	margin: 5px 0;
}

.profile-container>.profile-submit>button {
	margin: 10px 0 0 0;
	font-size: 18px;
	font-weight: 600;
	width: 20%;
	height: 42px;
	border-radius: 5px;
	color: white;
	border: none;
	background-color: #769fcd;
}

.profile-container>.profile-submit>button:hover {
	transform: scale(1.05);
	transition: 0.2res;
}