.loading-wrapper {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 998;
}

.loading-container {
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: 40%;
}

.loading-wrapper>h2 {
	position: absolute;
	z-index: 999;
}

.loading-heading {
	font-size: 3rem;
	font-weight: bold;
	color: #333;
}